import React, { Component } from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import PageWrapper from '../components/Structure/PageWrapper'
import TextDefault from '../components/Atoms/Text'
import Headline from '../components/Atoms/Headline'
import Servicer from '../components/Clean/ServiceList'
import GridCard from '../components/Atoms/GridCard'

const Tinyhed = styled.h4`
	${tw`text-xs uppercase tracking-wide mb-2`};
`

const Sidetext = styled.p`
	${tw`text-sm font-medium leading-normal ignoreReading`};
`

const OuterWidth = styled.div`
	${tw`px-6 md:px-12`};
`

const FlexWrapper = styled.div`
	${tw`flex flex-wrap -mx-2 md:-mx-3`};
`

const OneFull = styled.div`
	${tw`w-full px-2 md:px-3`};
`

const OneHalf = styled.div`
	${tw`w-1/2 px-2 md:px-3`};
`

const TwoThirds = styled.div`
	${tw`w-full md:w-2/3 px-3`};
`

const OneThird = styled.div`
	${tw`w-full md:w-1/4 px-3 ml-auto`};
	// div h4::first-child {
	// 	${tw`mt-0`};
	// }
`

const Box = styled.div`
	// ${tw`bg-grey-light`};
`

// const StyledGridCard = styled(GridCard)`
// 	a {
// 		background: #ff1d25;
// 	}
// `

// ${tw`bg-red`};

class Work extends Component {
	render() {
		const {
			data: {
				craft: { entry, awarding, notrelated }
			}
		} = this.props

		const overview =
			entry.projectInfo[0] && entry.projectInfo[0].overview
				? entry.projectInfo[0].overview
				: null

		const challenge =
			entry.projectInfo[0] && entry.projectInfo[0].challenge
				? entry.projectInfo[0].challenge
				: 'carrots'

		const outcome =
			entry.projectInfo[0] && entry.projectInfo[0].outcome
				? entry.projectInfo[0].outcome
				: 'noodles'

		const generatedTitle =
			entry.projectInfo[0] && entry.projectInfo[0].headline
				? entry.projectInfo[0].headline
				: entry.title

		const awardblock =
			!awarding || !awarding.length ? null : (
				<div className="w-1/2 md:w-full px-3 md:mb-6">
					<Tinyhed>Awards</Tinyhed>
					{awarding.map((entry, i) => (
						<Sidetext key={i}>{entry.title}</Sidetext>
					))}
				</div>
			)

		const servicesblock =
			!entry.serviceSelector || !entry.serviceSelector.length ? null : (
				<div className="w-1/2 md:w-full px-3 md:mb-6">
					<Tinyhed>Services</Tinyhed>
					<Servicer serviceSelector={entry.serviceSelector} />
				</div>
			)

		const moreprojects =
			!entry.relatedProjectPicker || !entry.relatedProjectPicker.length
				? notrelated
				: entry.relatedProjectPicker

		const moreprojectsblock = moreprojects && (
			<FlexWrapper>
				<OneFull>
					<OuterWidth>
						<Headline text="More Projects" sizing="sm" margin="2rem 0 1rem 0" />
					</OuterWidth>
				</OneFull>
				{moreprojects.map((entry, i) => (
					<OneHalf key={i}>
						<GridCard
							background="transparent"
							entry={entry}
							imageObject={entry.primaryThumb}
							cardclasses="min-h-240 md:min-h-300"
						/>
					</OneHalf>
				))}
			</FlexWrapper>
		)

		const isAwardNull = !awardblock
		const isAwardEmpty = !isAwardNull && !awardblock.length

		const isServicesNull = !servicesblock
		const isServicesEmpty = !isServicesNull && !servicesblock.length

		const topsidebarcontent = [
			isAwardNull ? null : awardblock,
			isServicesNull ? null : servicesblock
		]

		// function topsidebarthing(props) {
		// 	const hasContent = props.hasContent;
		// 	if (hasContent) {
		// 		return <UserGreeting />;
		// 	}
		// 	return <GuestGreeting />;
		// }

		// function Topsidebarthing(props) {
		// 	const hasContent = props.hasContent;
		// 	return (
		// 		<div>
		// 			<h1>Hello!</h1>
		// 			{awardblock.length > 0 &&
		// 				<h2>
		// 					You have {unreadMessages.length} unread messages.
		// 				</h2>
		// 			}
		// 		</div>
		// 	);
		// }

		return (
			<PageWrapper
				location={this.props.location}
				entry={entry}
				imageObject={
					!entry.primaryThumb || !entry.primaryThumb.length
						? null
						: entry.primaryThumb[0]
				}
				title={entry.title}
				matrixBuilder={entry.matrixBuilder}
				topsidebar={topsidebarcontent}
				toplede={overview && <TextDefault body={overview} large />}
				bonusContent={moreprojectsblock}
			/>
		)
	}
}

export default Work

export const workQuery = graphql`
	query QueryWork($id: [Int]!) {
		craft {
			entry(id: $id) {
				__typename
				... on Craft_Work {
					pageTheme
					title
					headline
					matrixBuilder {
						...MatrixBuilderQuery
					}
					primaryThumb {
						url
						focalPoint {
							x
							y
						}
					}
					projectInfo {
						... on Craft_ProjectInfoSuperTableBlockType {
							headline
							overview {
								content
							}
							challenge
							outcome
						}
					}
					serviceSelector {
						id
						title
						icon
					}
					relatedProjectPicker {
						id
						__typename
						... on Craft_Work {
							title
							uri
							primaryThumb {
								url
								focalPoint {
									x
									y
								}
							}
							headline
						}
					}
				}
			}
			awarding: entries(relatedTo: [{ element: $id }], section: awards) {
				title
			}
			notrelated: entries(
				idNot: $id
				section: work
				limit: 2
				orderBy: "RAND()"
			) {
				__typename
				id
				... on Craft_Work {
					title
					uri
					primaryThumb {
						url
						focalPoint {
							x
							y
						}
					}
					headline
				}
			}
		}
	}
`

// <pre>{JSON.stringify(entry, null, 2)}</pre>

// <ServiceSelector serviceSelector={entry.serviceSelector} />

// <Tinyhed>Services</Tinyhed>
// <Sidetext>
// 	{' '}
// 	{entry.serviceSelector ? (
// 		<pre>{JSON.stringify(entry.serviceSelector, null, 2)}</pre>
// 	) : (
// 		<h2>No services selected.</h2>
// 	)}
// </Sidetext>

// <Tinyhed>Challenge</Tinyhed>
// <Sidetext>{challenge}</Sidetext>
// <Tinyhed>Outcome</Tinyhed>
// <Sidetext>{outcome}</Sidetext>
// <pre>{JSON.stringify(awarding, null, 2)}</pre>
// <pre>{JSON.stringify(notrelated, null, 2)}</pre>
